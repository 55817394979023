// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // eslint-disable-line
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
/** Components */
import MainLayout from "./features/Layout/MainLayout";
import { Walls, SystemMVC } from "./features/Views";
import Conversations from "./features/Views/Conversations";
import Groups from "./features/Views/Groups";
import WebHooksContainer from "./features/Views/WebHooks";
import ScheduleContainer from "./features/Views/Schedules";
import EndingContainer from "./features/Views/Ending";
import LabelContainer from "./features/Views/Label";
import FlowContainer from "./features/Views/Flow";
import EditFlows from "./features/Views/EditFlows";
import WebChatContainer from "./features/Views/WebChat";
import WebChatEditContainer from "./features/Views/WebChatEdit";
import EmailContainer from "./features/Views/Email";
import EmailEditContainer from "./features/Views/EmailEdit";
import SmsContainer from "./features/Views/Sms";
import FormContainer from "./features/Views/Form";
import FormEditContainer from "./features/Views/FormEdit";
import PbxContainer from "./features/Views/Pbx";
import WhatsAppContainer from "./features/Views/WhatsApp";
import FacebookContainer from "./features/Views/Facebook";
import FacebookEditContainer from "./features/Views/FacebookEdit";
import InstagramContainer from "./features/Views/Instagram";
import InstagramEditContainer from "./features/Views/InstagramEdit";
import MercadoLibreContainer from "./features/Views/MercadoLibre";
import MercadoLibreEditContainer from "./features/Views/MercadoLibreEdit";
import GoogleBusinessContainer from "./features/Views/GoogleBusiness";
import GoogleBusinessEditContainer from "./features/Views/GoogleBusinessEdit";
import SipContainer from "./features/Views/Sip";
import WhatsAppEditContainer from "./features/Views/WhatsAppEdit";
import SmsEditContainer from "./features/Views/SmsEdit";
import { Dashboard } from "features/Views/Dashboard";
import ResponsesContainer from "./features/Views/Responses";
import MyReports from "./features/Views/MyReports/MyReports";
import { ReportDetails } from "./features/Views/MyReports/components";
import SchedulesEditContainer from "features/Views/SchedulesEdit";
import QuickResponseContainer from "features/Views/QuickResponse/QuickResponseContainer";
import TemplatesContainer from "features/Views/Templates/TemplatesContainer";
import DateTimeProvider from "context/DateTime/DateTimeProvider";
// Context
import { useContext, useEffect } from "react";
import { socketContext } from "context/WebSocketProvider/SocketProvider";
// Types
import { IUserUpdate } from "@trii/types/dist/Common/EventsQueue/UserUpdate";
import { selectUser, updateUser } from "redux/features/userSlice/userSlice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useSelector } from "react-redux";
// IFRAME
import DashboardIFRAME from "features/Views/DashboardIFRAME";
import ConversationsIFRAME from "features/Views/ConversationsIFRAME";
import MyReportsIFRAME from "features/Views/MyReportsIFRAME";
import SystemMVCFRAME from "features/Views/SystemMVCFRAME";
import MainMenu from "features/Views/MainMenu/MainMenu";
import RCS from "features/Views/RCS/RCS";
import RCSEditContainer from "features/Views/RCSEdit/RCSEditContainer";
import NotAllowed from "features/Views/NotAllowed/NotAllowed";
import ProtectedRoute from "functions/ProtectedRoute";
const Routes = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);

  useEffect(() => {
    const handleUserUpdate = (updatedUserData: IUserUpdate) => {
      dispatch(updateUser(updatedUserData));

      if (updatedUserData.userId === user.uid) {
        localStorage.setItem(
          "userStatus",
          JSON.stringify(updatedUserData.status)
        );
      }
    };

    if (socketConnection) {
      subscribeEvent("user_update", handleUserUpdate);
    }

    return () => {
      unsubscribeEvent("user_update");
    };
  }, [socketConnection]);


  return (
    <DateTimeProvider>
      <MainLayout>
      <ReactRoutes>
        <Route
          path="/a/conversations"
          element={<Navigate to="/a/conversations/Conversations" replace />}
        />
        <Route path="/a/conversations/not" element={<NotAllowed />} />
        
        {/* Protected Routes */}
        <Route
          path="/a/conversations/*"
          element={
            <ProtectedRoute>
              <Conversations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/Dashboard/:dashboardType?"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
          caseSensitive
        />
        <Route
          path="/a/conversations/socialwalls"
          element={
            <ProtectedRoute>
              <Walls />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/Conversations/:contactId?"
          element={
            <ProtectedRoute>
              <Conversations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/quick-responses"
          element={
            <ProtectedRoute>
              <QuickResponseContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/templates"
          element={
            <ProtectedRoute>
              <TemplatesContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/System"
          element={
            <ProtectedRoute>
              <SystemMVC />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/webhooks"
          element={
            <ProtectedRoute>
              <WebHooksContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/System/endings"
          element={
            <ProtectedRoute>
              <EndingContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/labels"
          element={
            <ProtectedRoute>
              <LabelContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/groups"
          element={
            <ProtectedRoute>
              <Groups />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/schedule"
          element={
            <ProtectedRoute>
              <ScheduleContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/schedule/:id"
          element={
            <ProtectedRoute>
              <SchedulesEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/flows"
          element={
            <ProtectedRoute>
              <FlowContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/flows/:id"
          element={
            <ProtectedRoute>
              <EditFlows />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/emails"
          element={
            <ProtectedRoute>
              <EmailContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/emails/:id"
          element={
            <ProtectedRoute>
              <EmailEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/webchats"
          element={
            <ProtectedRoute>
              <WebChatContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/webchats/:id"
          element={
            <ProtectedRoute>
              <WebChatEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/forms"
          element={
            <ProtectedRoute>
              <FormContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/forms/:id"
          element={
            <ProtectedRoute>
              <FormEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/pbx"
          element={
            <ProtectedRoute>
              <PbxContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/whatsapp"
          element={
            <ProtectedRoute>
              <WhatsAppContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/whatsapp/:id"
          element={
            <ProtectedRoute>
              <WhatsAppEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/facebook"
          element={
            <ProtectedRoute>
              <FacebookContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/facebook/:id"
          element={
            <ProtectedRoute>
              <FacebookEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/instagram"
          element={
            <ProtectedRoute>
              <InstagramContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/instagram/:id"
          element={
            <ProtectedRoute>
              <InstagramEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/mercadolibre"
          element={
            <ProtectedRoute>
              <MercadoLibreContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/mercadolibre/:id"
          element={
            <ProtectedRoute>
              <MercadoLibreEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/sms"
          element={
            <ProtectedRoute>
              <SmsContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/sms/:id"
          element={
            <ProtectedRoute>
              <SmsEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/googlebusiness"
          element={
            <ProtectedRoute>
              <GoogleBusinessContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/googlebusiness/:id"
          element={
            <ProtectedRoute>
              <GoogleBusinessEditContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/sip"
          element={
            <ProtectedRoute>
              <SipContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/responses"
          element={
            <ProtectedRoute>
              <ResponsesContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/schedule"
          element={
            <ProtectedRoute>
              <ScheduleContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/MyReports"
          element={
            <ProtectedRoute>
              <MyReports />
            </ProtectedRoute>
          }
          caseSensitive
        />
        <Route
          path="/a/conversations/myReports/view"
          element={
            <ProtectedRoute>
              <ReportDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/RCS"
          element={
            <ProtectedRoute>
              <RCS />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/conversations/system/RCS/:id"
          element={
            <ProtectedRoute>
              <RCSEditContainer />
            </ProtectedRoute>
          }
        />
      </ReactRoutes>
    </MainLayout>
    </DateTimeProvider>
  );
};

export default Routes;
