import React, { useContext, useEffect, useRef } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box } from '@mui/material';
// Components
import { Attachments, DestinationBox, FromBox, Subject } from './components';
import PlaygroundNodes from 'components/EmailEditor/nodes/PlaygroundNodes';
import PlaygroundEditorTheme from 'components/EmailEditor/themes/PlaygroundEditorTheme';
// Lexical
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { TableContext } from 'components/EmailEditor/plugins/TablePlugin';
import { SharedAutocompleteContext } from 'components/EmailEditor/context/SharedAutocompleteContext';
import { IContactAddress } from '@trii/types/dist/Contacts';
import { EmailEditor } from 'components';
import { EmailMessageContext } from '../../context/EmailMessageContext';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import {
  selectIsReplyingEmail,
  setIsReplyingEmail,
} from 'redux/features/messagesSlice/messagesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { sub } from 'date-fns';

interface BodyProps {
  openModal: boolean;
}

const Body = ({ openModal }: BodyProps) => {
  const dispatch = useAppDispatch();

  const {
    editorState,
    from,
    to,
    isCc,
    cc,
    isBcc,
    bcc,
    setTo,
    setIsCc,
    setCc,
    setIsBcc,
    setBcc,
    handleUploadFile,
    setEditorState,
    setFooterSize,
    getContactFields,
    getVariableInfo,
    attachments,
    handleSaveEmailDraftInDB,
    bodyDraft,
    emailConversationHasDraft,
  } = useContext(messagesContext);
  const { body, setBody, subject, sendEmailAndReset } =
    useContext(EmailMessageContext);
  const { contactInfo } = useContext(conversationsContext);
  const bodyRef = useRef(body);
  const subjectRef = useRef(subject);
  const fromRef = useRef(from);
  const toRef = useRef(to);
  const ccRef = useRef(cc);
  const bccRef = useRef(bcc);
  const attachmentsRef = useRef(attachments);

  const conversationSelected = useSelector(selectConversationSelected);
  const isReplyingEmail = useSelector(selectIsReplyingEmail);

  // const handleSetIsReplyingEmail = (value: boolean) => {
  //   dispatch(setIsReplyingEmail(value));
  // };

  const { t } = useTranslation();

  const handleChangeEmailTo =
    (setState: React.Dispatch<React.SetStateAction<IContactAddress[]>>) =>
      (event: React.ChangeEvent<{}>, newValue: IContactAddress[]) => {
        console.log('newValue', newValue);
        setState(newValue);
      };

  const initialConfig = {
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  // Check if the current values are different from the previous ones
  const prevDeps = useRef({ subject, body, to, cc, bcc, attachments });

  useEffect(() => {
    const originalToEmail = contactInfo?.emails?.find(
      (email) => email.id === conversationSelected.remoteAddressId
    );
    // console.log("prevDeps.current.body !== ''", prevDeps.current.body !== '');
    // console.log(' prevDeps.current.body !== body', prevDeps.current.body !== body);
    // console.log(' prevDeps.current.body !== body', prevDeps.current.body !== body);
    // console.log("body?.trim() !== ''", body?.trim() !== '');

    if (
      (prevDeps.current.subject !== subject && subject.trim() !== '') ||
      ((prevDeps.current.body !== '' ||
        (prevDeps.current.body !== body && body !== bodyDraft)) &&
        body?.trim() !== '') ||
      (prevDeps.current.to !== to &&
        to.length > 0 &&
        to[0]?.address !== originalToEmail?.address) ||
      (prevDeps.current.cc !== cc && cc.length > 0) ||
      (prevDeps.current.bcc !== bcc && bcc?.length > 0) ||
      (prevDeps.current.attachments !== attachments && attachments?.length > 0)
    ) {
      // Debug by console logings the conditions of the if statement
      // console.log(
      //   "prevDeps.current.subject !== subject && subject.trim() !== ''",
      //   prevDeps.current.subject !== subject && subject.trim() !== ''
      // );
      // console.log(
      //   "((prevDeps.current.body !== '' || prevDeps.current.body !== null) && body?.trim() !== '')",
      //   (prevDeps.current.body !== '' || prevDeps.current.body !== null) &&
      //     body?.trim() !== ''
      // );
      // console.log(
      //   'prevDeps.current.to !== to && to.length > 0 && to[0]?.address !== originalToEmail?.address',
      //   prevDeps.current.to !== to &&
      //     to.length > 0 &&
      //     to[0]?.address !== originalToEmail?.address
      // );
      // console.log(
      //   'prevDeps.current.cc !== cc && cc.length > 0',
      //   prevDeps.current.cc !== cc && cc.length > 0
      // );
      // console.log(
      //   'prevDeps.current.bcc !== bcc && bcc.length > 0',
      //   prevDeps.current.bcc !== bcc && bcc.length > 0
      // );
      // console.log(
      //   'prevDeps.current.attachments !== attachments && attachments.length > 0',
      //   prevDeps.current.attachments !== attachments && attachments.length > 0
      // );
      prevDeps.current = { subject, body, to, cc, bcc, attachments };
      const timer = setTimeout(async () => {
        handleSaveEmailDraftInDB(conversationSelected.id, subjectRef.current, bodyRef.current, fromRef.current, toRef.current, ccRef.current, bccRef.current, attachmentsRef.current);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [subject, body, to, cc, bcc, attachments]);

  useEffect(() => {
    if (subjectRef.current !== subject) {
      subjectRef.current = subject;
    }
    if (bodyRef.current !== body) {
      bodyRef.current = body;
    }
    if (subjectRef.current !== subject) {
      subjectRef.current = subject;
    }
    if (fromRef.current !== from) {
      fromRef.current = from;
    }
    if (toRef.current !== to) {
      toRef.current = to;
    }
    if (ccRef.current !== cc) {
      ccRef.current = cc;
    }
    if (bccRef.current !== bcc) {
      bccRef.current = bcc;
    }
    if (attachmentsRef.current !== attachments) {
      attachmentsRef.current = attachments;
    }
  }, [subject, body, subject, from, to, cc, bcc, attachments]);

  // console.log('Body: ', body);
  // console.log('Body draft: ', bodyDraft);

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <FromBox />
        <DestinationBox
          title={t('conversations.message.email.to')}
          isCc={isCc}
          setIsCc={setIsCc}
          isBcc={isBcc}
          setIsBcc={setIsBcc}
          selectedList={to}
          handleChangeEmailTo={handleChangeEmailTo(setTo)}
          openModal={openModal}
        />
        {isCc && (
          <DestinationBox
            title={t('conversations.message.email.cc')}
            isCc={isCc}
            setIsCc={setIsCc}
            isBcc={isBcc}
            setIsBcc={setIsBcc}
            selectedList={cc}
            handleChangeEmailTo={handleChangeEmailTo(setCc)}
            openModal={openModal}
          />
        )}
        {isBcc && (
          <DestinationBox
            title={t('conversations.message.email.bcc')}
            isCc={isCc}
            setIsCc={setIsCc}
            isBcc={isBcc}
            setIsBcc={setIsBcc}
            selectedList={bcc}
            handleChangeEmailTo={handleChangeEmailTo(setBcc)}
            openModal={openModal}
          />
        )}
        <Subject />
        <Attachments />
        <Box
          sx={{
            width: '100%',
            height: `calc(100% - ${isCc && isBcc ? '17rem' : isCc || isBcc ? '14rem' : '11rem'
              })`,
            position: 'relative',
            borderTop: (theme) => `2px solid ${theme.palette.divider}`,
          }}
        >
          <TableContext>
            <SharedAutocompleteContext>
              <EmailEditor
                isReplyingEmail={isReplyingEmail}
                // setIsReplyingEmail={handleSetIsReplyingEmail}
                openModal={openModal}
                body={body}
                bodyDraft={bodyDraft}
                setBody={setBody}
                handleUploadFile={handleUploadFile}
                editorState={editorState}
                setEditorState={setEditorState}
                from={from}
                to={to}
                setFooterSize={setFooterSize}
                conversationSelected={conversationSelected}
                sendEmail={sendEmailAndReset}
                getContactFields={getContactFields}
                getVariableInfo={getVariableInfo}
              />
            </SharedAutocompleteContext>
          </TableContext>
        </Box>
      </Box>
    </LexicalComposer>
  );
};

export default Body;
