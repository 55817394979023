import {
  useState,
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import sanitizeHtml from 'sanitize-html';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box } from '@mui/material';
// Theme
import { useTheme } from '@mui/material/styles';
// Markdown
import { Markdown } from 'functions/Markdown';
// Components
import { Response, Templates, Variable, Variables } from './components';
import { TemplatesProvider } from 'features/Views/Templates/context/TemplatesProvider';
import { useSearchParams } from 'react-router-dom';

type InputProps = {
  value: string;
  newEmoji: string;
  conversationId: string;
  contactId: string;
  maxHeigth?: string;
  setValue: (value: string) => void;
  setNewEmoji: (value: string) => void;
  handleSendMessage: (value: string) => void;
  disabled?: boolean;
  disableEnterKeyAction?: boolean;
};

const MENTION_REGEX = /@(\w*)$/g;
const SLASH_WITHOUT_SPACE_REGEX = /\/(\w*)$/g;
const SLASH_REGEX = /\/([^\/]*)\s*$/g;
const BACKSLASH_REGEX = /\\([^\\]*)\s*$/g;
const DOUBLE_BRACE_REGEX = /\{\{/g;

const Input = ({
  value,
  newEmoji,
  conversationId,
  contactId,
  maxHeigth = null,
  setValue,
  setNewEmoji,
  handleSendMessage,
  disabled = false,
  disableEnterKeyAction = false,
}: InputProps) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLDivElement>(null);
  const [content, setContent] = useState<string>('');

  const [searchParams] = useSearchParams();
  const contactIdParam = searchParams.get('contactId');

  const [variableAnchorEl, setVariableAnchorEl] = useState<null | HTMLDivElement>(
    null
  );
  const [responseAnchorEl, setResponseAnchorEl] = useState<null | HTMLDivElement>(
    null
  );
  const [templatesAnchorEl, setTemplatesAnchorEl] = useState<null | HTMLDivElement>(
    null
  );
  const [variablesAnchorEl, setVariablesAnchorEl] = useState<null | HTMLDivElement>(
    null
  );
  const [variable, setVariable] = useState<string>('');
  const [variables, setVariables] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [templates, setTemplates] = useState<string>('');
  const [variableFocus, setVariableFocus] = useState<boolean>(false);
  const [caretPosition, setCaretPosition] = useState<number>(0);

  let variableLength = 0;

  const onContentChange = useCallback((evt) => {
    const sanitizeConf = {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'span',
        'strong',
        'em',
        'code',
        's',
        'u',
        'br',
      ]),
      allowedAttributes: {
        span: ['class', 'style'],
      },
      parseStyleAttributes: false,
    };

    return sanitizeHtml(evt.target.innerHTML, sanitizeConf);
  }, []);

  // const checkAtSymbolBeforeCursor = (regexFunction: RegExp) => {
  //   const text = inputRef.current?.textContent || '';
  //   const textBeforeCursor = text.slice(0, caretPosition);

  //   const match = regexFunction.exec(textBeforeCursor);
  //   const isMatch =
  //     match && match?.index + match[0].length === textBeforeCursor.length;

  //   if (regexFunction === DOUBLE_BRACE_REGEX && match) {
  //     console.log('textBeforeCursor', textBeforeCursor);
  //     console.log('match', match);
  //     console.log('match index', match?.index);
  //     console.log('match[0].length', match[0]?.length);
  //     console.log('textBeforeCursor.length', textBeforeCursor?.length);
  //   }

  //   return {
  //     isMatch,
  //     match,
  //   };
  // };

  const checkAtSymbolBeforeCursor = (regexFunction: RegExp) => {
    const text = inputRef.current?.textContent || '';
    const textBeforeCursor = text.slice(0, caretPosition);

    const matches = [...textBeforeCursor.matchAll(regexFunction)];

    const lastMatch = matches[matches.length - 1];
    const isMatch =
      lastMatch &&
      lastMatch.index! + lastMatch[0].length === textBeforeCursor.length;

    return {
      isMatch,
      match: lastMatch,
    };
  };

  const getCaret = () => {
    let caretAt = 0;
    const sel = window.getSelection();

    if (sel.rangeCount === 0) {
      return caretAt;
    }

    const range = sel.getRangeAt(0);
    const preRange = range.cloneRange();
    preRange.selectNodeContents(inputRef.current);
    preRange.setEnd(range.endContainer, range.endOffset);
    const modifiedHtml = preRange.toString();

    caretAt = modifiedHtml.length;

    setCaretPosition(caretAt);
  };

  let positionFound = false;
  let text = 0;
  let caret = caretPosition;

  const searchCaret = (nodes: NodeListOf<ChildNode>, range: Range) => {
    nodes.forEach((node) => {
      if (node.childNodes.length > 0) {
        searchCaret(node.childNodes, range);
        return;
      }
      text += node.textContent.length;
      if (caretPosition <= text && caretPosition >= caret && !positionFound) {
        caret =
          caretPosition - caret === 0
            ? caretPosition > text
              ? text
              : caretPosition
            : caretPosition - caret;
        try {
          const newCaret =
            variableLength > 0 ? caretPosition + variableLength : caret;
          range.setStart(node, newCaret);
          range.collapse(true);
        } catch (error) {
          range.selectNodeContents(node);
          range.collapse(false);
        } finally {
          positionFound = true;
          variableLength = 0;
          return;
        }
      }
      if (!positionFound) {
        if (text > 0) {
          caret = text;
        } else {
          caret += 1;
        }
      }
    });
  };

  const setCaret = () => {
    const element = inputRef.current;
    if (element && element.childNodes.length > 0) {
      const sel = window.getSelection();
      if (sel.type !== 'Caret') {
        return;
      }
      const range = document.createRange();
      searchCaret(element.childNodes, range);

      sel.removeAllRanges();
      sel.addRange(range);
      element.focus();
    }
  };

  const applyRegex = (text: string) => {
    getCaret();
    setValue(text);

    if (!text.trim()) {
      setContent('');
      return;
    }
    const modifiedHTML = Markdown(text, true);
    setContent(modifiedHTML);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    getCaret();
    if (e.key === 'Enter' && !e.shiftKey && !disableEnterKeyAction) {
      e.preventDefault();
      e.stopPropagation();
      handleSendMessage(value);
      setContent('');
      return;
    }
    if (e.key === 'Enter' && !e.shiftKey && disableEnterKeyAction) {
      e.preventDefault();
      e.stopPropagation();
      const input = inputRef.current;
      if (input) {
        const text = input.textContent || '';
        const textBeforeCursor = text.slice(0, caretPosition);
        const textAfterCursor = text.slice(caretPosition);
        const newText = textBeforeCursor + '\n' + textAfterCursor;
        applyRegex(newText);
        setCaretPosition(caretPosition + 1);
        return;
      }
    }
    if (
      (variableAnchorEl ||
        responseAnchorEl ||
        templatesAnchorEl ||
        variablesAnchorEl) &&
      (e.key === 'ArrowUp' || e.key === 'ArrowDown')
    ) {
      e.preventDefault();
      e.stopPropagation();
      setVariableFocus(true);
      console.log('variableFocus', variableFocus);
      return;
    }
    if (
      e.key === 'Escape' &&
      (variableAnchorEl ||
        responseAnchorEl ||
        templatesAnchorEl ||
        variablesAnchorEl)
    ) {
      e.preventDefault();
      e.stopPropagation();
      handleClosePopover('!clear');
      return;
    }
    // Close the popover when the space key is pressed
    if (
      e.key === ' ' &&
      (variableAnchorEl ||
        responseAnchorEl ||
        templatesAnchorEl ||
        variablesAnchorEl)
    ) {
      e.preventDefault();
      e.stopPropagation();
      handleClosePopover('!clear');

      const input = inputRef.current;

      if (input) {
        const text = input.textContent || '';
        const textBeforeCursor = text.slice(0, caretPosition);
        const textAfterCursor = text.slice(caretPosition);
        const newText = textBeforeCursor + ' ' + textAfterCursor;
        applyRegex(newText);
        setCaretPosition(caretPosition + 1);
        return;
      }

      return;
    }
  };

  const handleFocus = () => {
    if (variableFocus) {
      setVariableFocus(false);

      inputRef.current?.focus();
    }
  };

  const handleClosePopover = (clearContent?: '!clear') => {
    setVariableAnchorEl(null);
    setVariablesAnchorEl(null);
    setResponseAnchorEl(null);
    setTemplatesAnchorEl(null);
    setVariableFocus(false);
    setResponse('');
    setVariable('');
    setTemplates('');
    // if (clearContent !== '!clear') setContent('');
  };

  const processTextReplacement = (
    regex: RegExp,
    variable: string,
    replaceFunction: (matches: RegExpMatchArray, textBeforeCursor: string) => string,
    lengthAdjustment: number = 1
  ) => {
    const input = inputRef.current;

    if (input) {
      const text = input.textContent || '';
      const textBeforeCursor = text.slice(0, caretPosition);
      const textAfterCursor = text.slice(caretPosition);

      const matches = textBeforeCursor.match(regex);

      if (matches) {
        const newText = replaceFunction(matches, textBeforeCursor) + textAfterCursor;
        applyRegex(newText);
        handleClosePopover();
        const variableLength = variable.length - lengthAdjustment;
        setCaretPosition(variableLength + caretPosition);
        setTimeout(() => {
          const input = inputRef.current;
          input.scrollTop = input.scrollHeight;
        }, 0);
      }
    }
  };

  // Wrapper functions for different replacement needs
  const handleSelectOption = (regex: RegExp) => (variable: string) => {
    processTextReplacement(
      regex,
      variable,
      (matches, textBeforeCursor) => {
        const mentionText = matches[0];
        const newVariable = mentionText.replace(regex, variable);
        return textBeforeCursor.replace(regex, newVariable);
      },
      1 // Default length adjustment
    );
  };

  const handleSelectVariablesOption = (regex: RegExp) => (variable: string) => {
    processTextReplacement(
      regex,
      variable,
      (matches, textBeforeCursor) => {
        const lastMatch = matches[matches.length - 1];
        const matchStart = textBeforeCursor.lastIndexOf(lastMatch);
        const matchEnd = matchStart + lastMatch.length;
        return (
          textBeforeCursor.slice(0, matchStart) +
          variable +
          textBeforeCursor.slice(matchEnd)
        );
      },
      2 // Different length adjustment for variablesOption
    );
  };

  useLayoutEffect(() => {
    if ((!content && value) || content !== value) {
      const modifiedHTML = Markdown(value, true);
      setContent(modifiedHTML);
    }
  }, [value]);

  useEffect(() => {
    const input = inputRef.current;
    if (input && !contactIdParam) {
      input.focus();
    }
  }, []);

  useEffect(() => {
    const isVariable = checkAtSymbolBeforeCursor(MENTION_REGEX);
    const isVariables = checkAtSymbolBeforeCursor(DOUBLE_BRACE_REGEX);
    const isResponse = checkAtSymbolBeforeCursor(SLASH_WITHOUT_SPACE_REGEX);
    const isTemplates = checkAtSymbolBeforeCursor(BACKSLASH_REGEX);

    if (isVariable.isMatch) {
      setVariableAnchorEl(inputRef.current);
      setVariable(isVariable.match[1]);
    } else {
      setVariableAnchorEl(null);
    }
    if (isResponse.isMatch) {
      setResponseAnchorEl(inputRef.current);
    }

    if (isTemplates.isMatch) {
      setTemplatesAnchorEl(inputRef.current);
    } else {
      setVariableAnchorEl(null);
    }

    if (isVariables.isMatch) {
      setVariablesAnchorEl(inputRef.current);
      // setVariable(isVariables.match[1]);
    } else {
      setVariablesAnchorEl(null);
    }

    if (caretPosition) {
      setCaret();
    }
  }, [caretPosition, content]);

  useEffect(() => {
    if (newEmoji) {
      const input = inputRef.current;
      input.focus();
      const sel = window.getSelection();
      if (input && sel) {
        // Obtener la posición del caret antes de insertar el emoji
        const range = sel.getRangeAt(0);
        const emojiNode = document.createTextNode(newEmoji);

        // Insertar el emoji en la posición actual del caret
        range.insertNode(emojiNode);

        // Mover el caret justo después del emoji insertado
        range.setStartAfter(emojiNode);
        range.setEndAfter(emojiNode);
        sel.removeAllRanges();
        sel.addRange(range);

        // Actualizar la posición del caret al final del contenido
        setCaretPosition(input.textContent.length);

        setNewEmoji('');

        // Aplicar el regex para procesar el nuevo contenido
        applyRegex(input.textContent || '');
      }
    }
  }, [newEmoji]);

  useEffect(() => {
    const variableMatch = value.match(MENTION_REGEX);

    if (variableMatch) {
      const mentionMatch = variableMatch[0].replace(
        MENTION_REGEX,
        (match, variable) => {
          return variable;
        }
      );
      setVariable(mentionMatch);
    } else {
      setVariable('');
    }

    if (Boolean(responseAnchorEl)) {
      const responseMatch = value.match(SLASH_REGEX);
      if (responseMatch) {
        const response = checkAtSymbolBeforeCursor(SLASH_REGEX);
        setResponse(response.match[1]);
      } else {
        setResponse('');
      }
    }
    if (Boolean(templatesAnchorEl)) {
      const templatesMatch = value.match(BACKSLASH_REGEX);
      if (templatesMatch) {
        const templates = checkAtSymbolBeforeCursor(BACKSLASH_REGEX);
        setTemplates(templates.match[2]);
      } else {
        setTemplates('');
      }
    }
    if (Boolean(variablesAnchorEl)) {
      const variablesMatch = value.match(DOUBLE_BRACE_REGEX);
      if (variablesMatch) {
        const variables = checkAtSymbolBeforeCursor(DOUBLE_BRACE_REGEX);
        setVariables(variables.match[2]);
      } else {
        setVariables('');
      }
    }
  }, [value]);

  return (
    <>
      <style>
        {`
        .emoji {
          display: inline-block;
          color: transparent;
          background-size: 1rem;
          background-repeat: no-repeat;
          background-position: center;
          caret-color: ${theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
          
        }

        .children-emoji::selection {
          background-color: rgba(150,150,150,.4);
        }
        `}
      </style>

      <Box
        id="conversation-input"
        onInput={(e) => applyRegex(e.currentTarget.textContent)}
        onBlur={onContentChange}
        ref={inputRef}
        role="textbox"
        dir="ltr"
        sx={{
          width: 'inherit',
          maxWidth: '100%',
          padding: '8px',
          minHeight: '2rem',
          maxHeight: maxHeigth ? maxHeigth : '6rem',
          overflowY: 'auto',
          whiteSpace: 'pre-wrap',
          fontSize: '.9rem',
          overflowWrap: 'anywhere',
          '&[contenteditable=true]:empty:before': {
            content: 'attr(placeholder)',
            color: (theme) => theme.palette.text.secondary,
            cursor: 'text',
          },
          borderRadius: '0.5rem',
          bgcolor: (theme) => `${theme.palette.grey[500]}50`,
          '&:focus': {
            outline: 'none',
          },
        }}
        contentEditable={!disabled}
        dangerouslySetInnerHTML={{ __html: content }}
        suppressHydrationWarning
        onKeyDown={(e) => handleKeyDown(e)}
        onFocus={getCaret}
        onClick={getCaret}
        // placeholder={t('conversations.message.messagePlaceholder')}
      />
      {variableAnchorEl && (
        <Variable
          variable={variable}
          anchorEl={variableAnchorEl}
          caretPosition={caretPosition}
          autoFocus={variableFocus}
          conversationId={conversationId}
          contactId={contactId}
          handleClose={handleClosePopover}
          onSelect={handleSelectOption(MENTION_REGEX)}
          handleFocus={handleFocus}
        />
      )}
      {responseAnchorEl && (
        <Response
          variable={response}
          anchorEl={responseAnchorEl}
          caretPosition={caretPosition}
          autoFocus={variableFocus}
          handleClose={handleClosePopover}
          onSelect={handleSelectOption(SLASH_REGEX)}
          handleFocus={handleFocus}
        />
      )}
      {variablesAnchorEl && (
        <Variables
          variable={variables}
          anchorEl={variablesAnchorEl}
          caretPosition={caretPosition}
          autoFocus={variableFocus}
          handleClose={handleClosePopover}
          onSelect={handleSelectVariablesOption(DOUBLE_BRACE_REGEX)}
          handleFocus={handleFocus}
        />
      )}
      {templatesAnchorEl && (
        <TemplatesProvider handleClose={handleClosePopover}>
          <Templates
            variable={templates}
            anchorEl={templatesAnchorEl}
            caretPosition={caretPosition}
            handleClose={handleClosePopover}
            onSelect={handleSelectOption(BACKSLASH_REGEX)}
            handleSendMessage={handleSendMessage}
          />
        </TemplatesProvider>
      )}
    </>
  );
};

export default Input;
