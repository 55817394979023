import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "redux/features/userSlice/userSlice";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const userPermissions = useSelector(selectUser);

  // Verificar si conversations está habilitado
  if (!userPermissions?.profile?.conversations?.enabled) {
    return <Navigate to="/a/conversations/not" replace />;
  }

  return children;
};

export default ProtectedRoute;
