import { useContext, useEffect, useState } from 'react';
// Context
// @ts-ignore
import { EmailMessageContext } from './EmailMessageContext.ts';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Types
// @ts-ignore
import type { EmailMessageContextType } from './EmailMessageContext.ts';

const EmailMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    subjectDraft,
    bodyDraft,
    sendEmail,
    emailConversationHasDraft,
    body,
    setBody,
    subject,
    setSubject,
  } = useContext(messagesContext);

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };

  const sendEmailAndReset = (
    conversationId: string,
    newBody: string,
    subject: string
  ) => {
    sendEmail(conversationId, newBody, subject);
    setSubject('');
  };

  useEffect(() => {
    if (subjectDraft) {
      setSubject(subjectDraft);
    }
  }, [subjectDraft, emailConversationHasDraft]);

  useEffect(() => {
    console.log('emailConversationHasDraft', emailConversationHasDraft);
    if (emailConversationHasDraft) {
      setBody(bodyDraft);
    }
  }, [bodyDraft, emailConversationHasDraft]);

  const value: EmailMessageContextType = {
    subject,
    handleSubjectChange,
    setSubject,
    sendEmailAndReset,
    body,
    setBody,
  };

  return (
    <EmailMessageContext.Provider value={value}>
      {children}
    </EmailMessageContext.Provider>
  );
};

export default EmailMessageProvider;
