import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/features/userSlice/userSlice';

const NotAllowed = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  return (
    <Box className={"conversations-fadeinup"}>
      <Box
        style={{
          display: "flex",
          marginTop: "10px",
          marginBottom: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          You dont have permission to access this module
        </Typography>
      </Box>

    </Box>
  );
}

export default NotAllowed