import { useState, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectContactsFetchStatus } from 'redux/features/contactInfoSlice/contactInfoSlice';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  useTheme,
  styled,
} from '@mui/material';
import {
  ArrowDropDown,
  ArrowDropUp,
  PersonAddAlt1,
  Business,
  Search,
} from '@mui/icons-material';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { IContactAddress, IContactInfo } from '@trii/types/dist/Contacts';
import { Pagination } from 'redux/features/contactInfoSlice/types/Pagination';
import getImage from 'functions/getImage';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { SASUserAvatar } from './components';

interface SearchContactProps {
  contactTitle: string;
  searchTitle: string;
  contactSelected: IContactInfo;
  handleSelectContact: (contact: IContactInfo) => void;
}

const DATA = {
  currentPage: 1,
  perPage: 10,
  order: 'ASC',
  orderColumn: 'name',
  format: 'IContactInfo',
  operator: 'OR',
  filter: [
    { column: 'name', value: '', condition: '' },
    { column: 'email', value: '', condition: '' },
    { column: 'phone', value: '', condition: '' },
  ],
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  position: 'relative',
}));

const StyledList = styled(Box)(({ theme }) => ({
  maxHeight: '15rem',
  overflowY: 'auto',
  paddingY: '0',
  '& li': {
    padding: 0,
  },
  // @ts-ignore
  backgroundColor: theme.palette.background.panel,
  backgroundImage:
    'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
  position: 'absolute',
  width: '100%',
  zIndex: 1,
  top: '37px',
  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
  border: '50px',
}));

const SearchContact = ({
  contactTitle,
  searchTitle,
  contactSelected,
  handleSelectContact,
}: SearchContactProps) => {
  const { setDestinationSelected, conversationSelected } =
    useContext(conversationsContext);
  const { handleSearchContact } = useContext(messagesContext);
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allContactsLoaded, setAllContactsLoaded] = useState<boolean>(false);
  const [contacts, setContacts] = useState<IContactInfo[]>([]);
  const contactsFetchStatus = useSelector(selectContactsFetchStatus);
  const theme = useTheme();
  const listRef = useRef<HTMLUListElement>(null);
  const [showList, setShowList] = useState<boolean>(false); // Estado para mostrar u ocultar la lista de contactos
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null); // Initialize the timer as null

  const handleSearch = async (value: string) => {
    setFirstLoad(false);
    setSearch(value);
    setCurrentPage(1);
    setContacts([]);
    clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      if (value) {
        setIsLoading(true);
        try {
          const filterValue = DATA.filter.map((item) => ({
            ...item,
            value,
          }));
          const newData = { ...DATA, filter: filterValue } as Pagination;
          const response = await handleSearchContact(newData);
          const contactsData: IContactInfo[] = response.contactos.map((contact) => ({
            ...contact,
          }));
          setContacts(contactsData);
          if (response.paginacion.currentPage >= response.paginacion.lastPage) {
            setAllContactsLoaded(true);
          } else {
            setAllContactsLoaded(false);
          }
        } catch (error) {
          console.error('Error al buscar contactos:', error);
        } finally {
          setIsLoading(false);
          setShowList(true);
        }
      } else {
        setIsLoading(false);
        setShowList(false);
      }
    }, 1000);

    setTimer(newTimer);
  };

  const fetchMoreContacts = async () => {
    if (!allContactsLoaded && !isNextPage) {
      setIsNextPage(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      const newData = {
        ...DATA,
        currentPage: newPage,
        filter: DATA.filter.map((item) => ({
          ...item,
          value: search,
        })),
      } as Pagination;
      setIsLoading(true);
      try {
        const response = await handleSearchContact(newData);
        const newContacts: IContactInfo[] = response.contactos.map((contact) => ({
          ...contact,
        }));
        setContacts((prevContacts) => [...prevContacts, ...newContacts]);
        if (response.paginacion.currentPage >= response.paginacion.lastPage) {
          setAllContactsLoaded(true);
        } else {
          setAllContactsLoaded(false);
        }
      } catch (error) {
        console.error('Error al cargar más contactos:', error);
      } finally {
        setIsLoading(false);
        setIsNextPage(false);
      }
    }
  };

  const handleChange = (value: IContactInfo) => {
    handleSelectContact(value);
    setSearch(value.name);

    if (!value) {
      setDestinationSelected([]);
      setContacts([]);
      setSearch('');
      setShowList(false);
    }
    setShowList(false);
  };

  const handleAddContact = () => {
    window.open('/a/contacts/contacts/create', '_blank');
  };

  const getContactInfoAddress = (addresses: IContactAddress[]) => {
    if (addresses && addresses.length > 0) {
      const text = addresses.map((address, i) => {
        const channelInfo = getImage(address.channelType, 15);
        return (
          <Typography key={address.id} noWrap variant="body1" fontSize={'.75rem'}>
            {channelInfo.icon} {address.address}{' '}
            {i < addresses.length - 1 ? ' - ' : ''}
          </Typography>
        );
      });
      return (
        <Box display="flex" alignItems="center">
          {text}
        </Box>
      );
    }
  };

  useEffect(() => {
    if (!contactSelected) {
      setDestinationSelected([]);
      setContacts([]);
      setSearch('');
      setShowList(false);
    }
  }, [contactSelected]);

  useEffect(() => {
    if (conversationSelected?.contactInfo) {
      handleChange(conversationSelected.contactInfo);
    }
  }, []);

  useEffect(() => {
    setIsLoading(contactsFetchStatus === 'loading');
  }, [contactsFetchStatus]);

  const handleScroll = useInfiniteScroll(fetchMoreContacts);

  const handleShowList = () => {
    setShowList(true);
  };

  const handleHideList = () => {
    setShowList(false);
  };

  return (
    <StyledBox>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          {contactTitle}
        </Typography>
        <IconButton color="primary" onClick={handleAddContact}>
          <PersonAddAlt1 />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box position="absolute" top="2rem" width="100%">
          {isLoading && (
            <LinearProgress
              sx={{
                borderRadius: 1,
              }}
            />
          )}
        </Box>
        <TextField
          placeholder={searchTitle}
          fullWidth
          size="small"
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  fontSize="small"
                  sx={{
                    color: theme.palette.text.disabled,
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {showList ? (
                  <ArrowDropUp
                    onClick={handleHideList}
                    fontSize="small"
                    sx={{
                      color: theme.palette.text.disabled,
                      cursor: 'pointer',
                    }}
                  />
                ) : (
                  <ArrowDropDown
                    onClick={handleShowList}
                    fontSize="small"
                    sx={{
                      color: theme.palette.text.disabled,
                      cursor: 'pointer',
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => {
            handleSearch(e.target.value);
            setShowList(true);
          }}
        />
        {contacts.length === 0 && search && !isLoading && !firstLoad && (
          <Box
            py={1}
            px={2}
            sx={{
              //@ts-ignore
              backgroundColor: theme.palette.background.panel,
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {t('conversations.createConversation.error.noContactsFound')}
            </Typography>
          </Box>
        )}
        {showList && contacts.length > 0 && (
          <StyledList component="ul" ref={listRef} onScroll={handleScroll}>
            {contacts.map((contact) => {
              return (
                <Box key={contact.id} component="li">
                  <ListItemButton onClick={() => handleChange(contact)}>
                    <ListItemIcon>
                      {contact.isBusiness ? (
                        <Business
                          sx={{
                            color: theme.palette.text.disabled,
                          }}
                        />
                      ) : (
                        <SASUserAvatar imageUrl={contact.imageUrl} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: '12px',
                      }}
                      primary={contact.name}
                      secondary={getContactInfoAddress(
                        contact.contactAddresses || []
                      )}
                    />
                  </ListItemButton>
                </Box>
              );
            })}
            {isLoading && (
              <Box display="flex" justifyContent="center" alignItems="center" py={2}>
                <Typography variant="body2">{t('loading')}</Typography>
              </Box>
            )}
          </StyledList>
        )}
      </Box>
    </StyledBox>
  );
};

export default SearchContact;
