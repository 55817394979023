import { useContext, useState, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
// Icons
import { Done, Lock, Logout } from '@mui/icons-material';
//Context
import socialWallsContext from 'features/Views/Walls/context/SocialWallsContext';
// Moment
import moment from 'moment';
// Types
import { PostAction } from 'redux/features/socialWallSlice/types/PostAction';
import {
  deletePost,
  setNewPosts,
} from 'redux/features/socialWallSlice/socialWallSlice';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { dbWorker } from 'db/db';
import { ChannelType } from '@trii/types/dist/Common/Channels';

const FORMAT_DATE = 'DD/MM/YYYY';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
  height: '3rem',
  padding: theme.spacing(1, 3),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.text.primary}`,
  padding: '2px',
  width: 30,
  height: 30,
  '& .MuiAvatar-img': {
    borderRadius: '50%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  height: '25px',
  paddingRight: '10px',
}));

export default function PublicationHeader() {
  const dispatch = useAppDispatch();
  const { postSelected, posts, fetchAction, setPostSelected } =
    useContext(socialWallsContext);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState(0);
  const [date, setDate] = useState('');
  const { t } = useTranslation();

  const handleFinalizePost = (action: 'out' | 'finalize') => {
    const postId = postSelected.id;
    const data = {
      postId,
      action,
    } as PostAction;

    if (action === 'finalize') {
      dispatch(deletePost(postId));

      dbWorker.postMessage({
        action: 'deletePost',
        data: postId,
      });
    }

    setPostSelected(null);
    fetchAction(data);
  };

  useEffect(() => {
    if (postSelected && postSelected.counters) {
      const { likes, comments } = postSelected.counters;
      setLikes(likes);
      setComments(comments);
      const newDate = moment(postSelected.createdAt).format(FORMAT_DATE);
      setDate(newDate);
    }
  }, [postSelected]);

  return (
    <StyledCard>
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <StyledAvatar alt="Remy Sharp" src={postSelected?.imageUrl} />
            <Box maxWidth="40vw">
              <Typography
                variant="subtitle2"
                ml={2}
                mb={'-5px'}
                className="conversations-ellipsified"
              >
                {postSelected?.details}
              </Typography>
              {postSelected.channelInfo.type !== ChannelType.MERCADOLIBRE && (
                <Typography variant="caption" ml={2}>
                  {t('socialWalls.publicationHeader', { likes, comments, date })}
                </Typography>
              )}
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
            <IconButton aria-label="out" onClick={() => handleFinalizePost('out')}>
              <Tooltip title={t('socialWalls.actions.out')} arrow placement="left">
                <Logout
                  fontSize="small"
                  sx={{
                    transform: 'rotate(180deg)',
                    color: (theme) => theme.palette.text.disabled,
                  }}
                />
              </Tooltip>
            </IconButton>
            <StyledButton onClick={() => handleFinalizePost('finalize')}>
              <Done fontSize="small" />
              <Typography fontSize="small" color="inherit">
                {t('socialWalls.actions.done')}
              </Typography>
            </StyledButton>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
}
