import { useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  fetchFields,
  selectContactFields,
  selectContactFieldsFetchStatus,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import {
  selectBusinessFields,
  selectBusinessStatusfetchBussinesFields,
} from 'redux/features/businessSlice/selectors';
// Components/ui
import {
  Box,
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  styled,
} from '@mui/material';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchBusinessFields } from 'redux/features/businessSlice/businessSlice';

type VariablesProps = {
  variable: string;
  anchorEl: HTMLDivElement | null;
  caretPosition: number;
  autoFocus: boolean;
  handleClose: () => void;
  onSelect: (variable: string) => void;
  handleFocus: () => void;
};

interface PopoverPosition {
  top: number;
  left: number;
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: '100%',
    maxWidth: 'max-content',
    maxHeight: 300,
    minWidth: 100,
  },
}));

const ContainerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

const HeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 1,
  padding: '0 1rem',
});

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.default,
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollBehavior: 'smooth',
  maxHeight: 200,
}));
const Variables = ({
  variable,
  anchorEl,
  caretPosition,
  autoFocus,
  handleClose,
  onSelect,
  handleFocus,
}: VariablesProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const contactCustomFields = useSelector(selectContactFields);
  const businessCustomFields = useSelector(selectBusinessFields);
  const businessFieldsFetchStatus = useSelector(
    selectBusinessStatusfetchBussinesFields
  );
  const contactFieldsFetchStatus = useSelector(selectContactFieldsFetchStatus);

  const isLoading =
    businessFieldsFetchStatus === 'loading' ||
    contactFieldsFetchStatus === 'loading';

  const [popoverPosition, setPopoverPosition] = useState<PopoverPosition>(null);
  const [variablesList, setVariablesList] = useState<string[]>([
    'contact.name',
    'contact.firstName',
    'contact.lastName',
    'contact.birthDate',
    'contact.businessName',
    'contact.tags',
    'contact.emails',
    'contact.phones',
    'contact.ims',
    'contact.street1',
    'contact.city1',
    'contact.state1',
    'contact.zipcode1',
    'contact.country1',
    'contact.street2',
    'contact.city2',
    'contact.state2',
    'contact.zipcode2',
    'contact.country2',
    'agent.name',
    'agent.firstName',
    'agent.lastName',
    'agent.birthDate',
    'agent.id',
    'agent.username',
  ]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape' || event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      handleFocus();
    }
  };

  useEffect(() => {
    dispatch(
      fetchFields({
        fetchFor: 'contact',
        filterBy: 'custom',
      })
    );
    dispatch(fetchBusinessFields('custom'));
  }, []);

  useEffect(() => {
    if (caretPosition > 0) {
      const sel = window.getSelection();
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        const left = rect.left - anchorEl?.getBoundingClientRect().left;
        const top = rect.top - anchorEl?.getBoundingClientRect().top;
        setPopoverPosition({ top, left });
      }
    }
  }, [anchorEl, variable]);

  // useEffect(() => {
  //   if (variable) {
  //     const filterResponse = variablesList?.filter((item) =>
  //       item.toLocaleLowerCase().includes(variable.toLocaleLowerCase())
  //     );
  //     setVariablesList(filterResponse);
  //   } else {
  //     setVariablesList(variablesList);
  //   }
  // }, [variablesList, variable]);

  useEffect(() => {
    if (contactCustomFields) {
      const contactFields = contactCustomFields.map((field) => field.nameKey);
      setVariablesList((prev) => [...prev, ...contactFields]);
    }
  }, [contactCustomFields]);

  useEffect(() => {
    if (businessCustomFields) {
      const businessFields = businessCustomFields.map((field) => field.nameKey);
      setVariablesList((prev) => [...prev, ...businessFields]);
    }
  }, [businessCustomFields]);

  return (
    <StyledPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableAutoFocus
      disableEnforceFocus
      disableEscapeKeyDown
      anchorOrigin={{
        vertical: popoverPosition ? popoverPosition.top : 0,
        horizontal: popoverPosition ? popoverPosition.left : 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onKeyDown={handleKeyDown}
    >
      <ContainerBox>
        <HeaderBox>
          <Typography variant="body2">Variables</Typography>
        </HeaderBox>
        <StyledMenuList autoFocus={autoFocus}>
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                margin: '1rem',
              }}
            />
          ) : variablesList?.length > 0 ? (
            variablesList.map((variable, index) => (
              <MenuItem
                key={index}
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                onClick={() => onSelect(`{{${variable}}}`)}
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    width: '100%',
                    height: '100%',
                    p: 1,
                  }}
                >
                  {variable}
                </Typography>
              </MenuItem>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{
                width: '100%',
                height: '100%',
                padding: '1rem',
              }}
            >
              {t('conversations.message.variableNoResults')}
            </Typography>
          )}
        </StyledMenuList>
      </ContainerBox>
    </StyledPopover>
  );
};

export default Variables;
