import { forwardRef, useContext, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Translations
import { useTranslation } from 'react-i18next';
// components/ui
import { Button, Box, Typography } from '@mui/material';
import {
  ConversationSection,
  ActiveConversation,
  EndedConversation,
} from './components';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { SectionCard } from '../../layout';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Slice
import {
  fetchConversationsOfContact,
  resetConversationsOfContact,
  resetConversationsOfContactFetchedTypes,
  selectConversationSelected,
  selectConversationsOfContact,
  selectConversationsOfContactFetchStatus,
  selectConversationsOfContactFetchedTypes,
} from 'redux/features/conversationsSlice/conversationsSlice';
// Types
import { ContactProps } from '../../types/ContactProps';
import { useAppDispatch } from 'hooks/useAppDispatch';

export interface conversationExampleI {
  id: number;
  user: string;
  firstName: string;
  cellphone: string;
  date: string;
}

const ContactConversation = forwardRef((props: ContactProps, ref) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    setModalTitle,
    setOpenModalList,
    setContactSelected,
    getUserInfo,
    contactInfo,
  } = useContext(conversationsContext);

  const conversationSelected = useSelector(selectConversationSelected);
  const conversationsOfContact = useSelector(selectConversationsOfContact);

  const activeConversations = conversationsOfContact.filter(
    (conversation) => !conversation.finalizedAt
  );
  const finalizedConversations = conversationsOfContact.filter(
    (conversation) => conversation.finalizedAt
  );

  const handleCreateConversation = () => {
    setModalTitle(t('conversations.createConversation.title'));
    setOpenModalList(true);

    // setContactSelected(conversationSelected.contactInfo);
    // getUserInfo(conversationSelected.contactInfo.id);
  };

  function fetchFinishedConversationsOfContact() {
    if (contactInfo) {
      const contactId = contactInfo.id;
      const data = {
        contactId,
        status: '3',
      };

      dispatch(fetchConversationsOfContact(data));
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetConversationsOfContactFetchedTypes());
      dispatch(resetConversationsOfContact());
    };
  }, []);

  return (
    <SectionCard
      title={t('conversations.conversations')}
      onClick={props.handleClick}
      tabName="contactConversation"
    >
      <Box ref={ref}>
        <Button
          startIcon={<AddBoxIcon />}
          variant="contained"
          size="small"
          sx={{
            width: '100%',
            textTransform: 'none',
            fontSize: '12px',
            fontWeight: 'bold',
            mb: 3,
          }}
          onClick={handleCreateConversation}
        >
          {t('conversations.createConversation.title')}
        </Button>
        <ConversationSection title={t('conversations.filters.active')}>
          {activeConversations?.map((conversation) => (
            <ActiveConversation key={conversation.id} conversation={conversation} />
          ))}
        </ConversationSection>
        <ConversationSection
          fetchFinishedConversationsOfContact={fetchFinishedConversationsOfContact}
          title={t('conversations.filters.finalized')}
          isExpanded
        >
          {finalizedConversations?.length > 0 ? (
            finalizedConversations.map((conversation) => (
              <EndedConversation key={conversation.id} conversation={conversation} />
            ))
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontSize: '12px' }}>
                {t('conversations.noFinalizedConversations')}
              </Typography>
            </Box>
          )}
        </ConversationSection>
      </Box>
    </SectionCard>
  );
});

export default ContactConversation;
